<template>
    <div class="container">
        <div class="filter-top" v-if="Flag">
            <el-form :model="filterData" ref="form" inline size="small">
                <el-form-item v-for="(item, index) in filters" :key="index">
                    <el-select
                        v-if="item.type === 'select'"
                        clearable
                        v-model="filterData[item.key]"
                        :placeholder="item.name"
                        @change="getData"
                    >
                        <el-option
                            v-for="(option, optionIndex) in item.list"
                            :key="optionIndex"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                    <el-cascader
                        v-else-if="item.type === 'selectArea'"
                        v-model="filterData[item.key]"
                        :placeholder="item.name"
                        :options="areaList"
                        :show-all-levels="false"
                        :props="{ expandTrigger: 'hover' }"
                        clearable
                        @change="getData"
                    ></el-cascader>
                    <date-picker
                        v-else-if="item.type === 'date'"
                        v-model="filterData[item.key]"
                        type="year"
                        placeholder="选择年"
                        @change="getData"
                    >
                        <template slot="prepend">{{ item.name }}</template>
                    </date-picker>
                    <el-input v-else @change="getData" v-model="filterData[item.key]" placeholder="请输入…" clearable>
                        <template slot="prepend">{{ item.name }}</template>
                    </el-input>
                </el-form-item>
                <el-form-item class="flex1">
                    <search-input
                        size="mini"
                        v-model="search"
                        placeholder="搜索..."
                        @suffixClick="getData"
                        @keyup.enter.native="getData"
                        clearable
                        suffix-icon="el-icon-search"
                        class="search"
                    ></search-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="main">
            <div class="top">
                <div class="sort-btn">
                    <div class="sort-item" @click="sort = {}" :class="{ active: Object.keys(sort).length === 0 }">
                        综合排序
                    </div>
                    <template v-for="(item, index) in sortKey">
                        <div
                            class="sort-item right"
                            @click="clickSort(item.key)"
                            :key="index"
                            :class="showClass(item.key)"
                        >
                            <span>{{ item.name }}</span>
                            <i class="el-icon-caret-top"></i>
                            <i class="el-icon-caret-bottom"></i>
                        </div>
                    </template>
                </div>
                <div class="sort-btn-con">
                    <div class="news" v-if="searchs">
                        <search-input
                            size="mini"
                            v-model="search"
                            placeholder="请输入名称或其他信息"
                            @suffixClick="getData"
                            @keyup.enter.native="getData"
                            clearable
                            suffix-icon="el-icon-search"
                            class="search"
                        ></search-input>
                    </div>
                    <div class="flex1" v-if="searchs">
                        <div class="text">当前结果共{{ value.length }}个数据</div>
                    </div>
                </div>
            </div>
            <div class="list" v-loading="fetchingData">
                <slot></slot>

                <div class="empty" v-if="empty && !fetchingData">
                    <i class="el-icon-takeaway-box"></i>
                    <div class="empty-text">暂无数据</div>
                </div>
            </div>
            <div class="footer">
                <el-pagination
                    @size-change="onSizeChange"
                    @current-change="onCurrentChange"
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalElements"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import SearchInput from '../SearchInput';
import DatePicker from '../DatePicker';
import pageableTable from '../../mixins/pageableTable';
import areaList from '../../utils/area';
import dayjs from 'dayjs';
export default {
    name: 'List',
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            }
        },
        newsList: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: ''
        },
        Flag: {
            type: Boolean,
            default: true
        },
        searchs: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Array,
            default: () => {
                return [
                    {
                        name: '筛选法律类型',
                        type: 'select',
                        key: 'field',
                        list: [{ label: '研发阶段', value: 'RESEARCH_AND_DEVELOPMENT' }]
                    }
                ];
            }
        },
        sortKey: {
            type: Array,
            default: () => {
                return [
                    {
                        name: '发布时间',
                        key: 'createdAt'
                    }
                ];
            }
        },
        defaultQuery: {
            type: Object,
            default: () => {
                return {};
            }
        },
        defaultSort: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    mixins: [pageableTable],
    data() {
        return {
            search: '',
            filterData: {},
            sortList: ['desc', 'asc'],
            areaList,
            areaValue: [],
        };
    },
    watch: {
        $route() {
            this.$emit('init');
        }
    },
    mounted() {
        if(this.newsList){
            this.sortStr= 'issuedAt,desc'
        }
    },
    methods: {
        init() {
            // console.log(this.filters);
            let info = {};

            [...this.filters].forEach(item => {
                if (item.type === 'selectArea') {
                    info[item.key] = [];
                } else {
                    info[item.key] = item.default || '';
                }
            });

            this.filterData = info;
            this.page = 1;
            this.getData();
        },
        clickInput() {
            console.log('ahggsgd');
        },
        beforeGetData(query) {
            let data = {};
            [...this.filters].forEach(item => {
                if (item.type === 'selectArea') {
                    query[item.key] = this.filterData[item.key].join('/');
                } else if (item.type === 'date') {
                    console.log();
                    query[item.key] = this.filterData[item.key]
                        ? dayjs(this.filterData[item.key]).startOf('year').format('YYYY-MM-DD') +
                          ',' +
                          dayjs(this.filterData[item.key]).endOf('year').format('YYYY-MM-DD')
                        : '';
                } else if (!item.noQuery) {
                    query[item.key] = this.filterData[item.key];
                } else {
                    data[item.key] = this.filterData[item.key];
                }
            });
            data = { ...this.defaultSort };
            data.query = { ...query, ...this.defaultQuery };

            data.search = this.search;

            return data;
        },
        changeFilter(key, val) {
            this.filterData[key] = val;
            this.getData();
        },
        showClass(key) {
            let _class = '';
            if (this.sort[key]) {
                _class += 'active ';
                _class += this.sort[key];
            }

            return _class;
        },
        clickSort(key) {
            // console.log(key);
            let index = -1;
            if (this.sort[key]) {
                index = this.sortList.indexOf(this.sort[key]);
            }

            this.changeSort(key, this.sortList[(index + 1) % 2]);
        },
        setList(list) {
            this.$emit('input', list);
        }
    },
    components: {
        SearchInput,
        DatePicker
    }
};
</script>

<style lang="less" scoped>
.container {
    .flex-col();
}
.filter-top {
    background-color: #fff;
    .flex();
    padding: 12px 10px 0 20px;

    /deep/.el-input-group__prepend {
        background-color: transparent;
        padding: 0 10px;
        font-size: 12px;
        color: #303133;
        border-radius: 0;
    }
    .el-form-item--small.el-form-item {
        margin-bottom: 12px;
    }

    /deep/.el-input__inner {
        border-radius: 0;
    }

    /deep/.el-input-group > .el-input__inner {
        width: 116px;
    }

    .el-select {
        width: 140px;
    }
    .el-cascader {
        width: 140px;
    }
}
.top {
    height: 56px;
    background-color: #fff;
    flex-shrink: 0;
    padding: 0 10px;
}

.main {
    // background-color: #fff;
    flex-grow: 1;
    margin-top: 20px;
    .flex-col();
}
.list {
    background-color: #fff;
    flex-grow: 1;
    min-height: 740px;
    margin-top: 20px;
}
.footer {
    align-self: center;
    padding: 50px;
    flex-shrink: 0;
}
/deep/ .el-select-dropdown__item.selected {
    color: #01a041;
}
/deep/ .el-pager li.active {
    color: #01a041;
}
.empty {
    padding: 300px 0;
    .flex-col();
    align-items: center;
    i {
        font-size: 100px;
        color: #939599;
    }

    .empty-text {
        font-size: 14px;
        color: #939599;
        margin-top: 20px;
    }
}
.top {
    .flex();
    justify-content: space-between;
}
.news {
    // margin-left: 420px;
}
.sort-btn-con {
    display: flex;
}
.sort-btn {
    .flex();
    align-items: stretch;
    height: 56px;
    line-height: 56px;
    .sort-item {
        padding: 0 10px;
        color: #818999;
        font-size: 13px;
        cursor: pointer;
        width: 52px;
        &.right {
            position: relative;
            padding-right: 20px;

            i {
                position: absolute;
                right: 5px;
                color: #818999;
                font-size: 8px;

                &.el-icon-caret-top {
                    bottom: 25px;
                }
                &.el-icon-caret-bottom {
                    top: 26px;
                }
            }

            &.desc {
                .el-icon-caret-bottom {
                    color: #01a041;
                }
            }

            &.asc {
                .el-icon-caret-top {
                    color: #01a041;
                }
            }
        }

        &:hover {
            color: #01a041;
        }

        &.active {
            color: #01a041;
        }
    }
}
.flex1 {
    flex-grow: 1;
    .flex();
    // justify-content: flex-end;
    // padding: 0 20px;

    .text {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-left: 20px;
        margin-right: 10px;
    }
    /deep/.el-form-item__content {
        width: 100%;
    }
}
.search {
    width: 260px;
    max-width: 260px;
    display: block;
    /deep/.el-input__inner {
        // background-color: @bg;
        // border-color: @bg;
        height: 32px;

        &:focus {
            border-color: #01a041;
        }
    }
    /deep/ .el-input__icon {
        line-height: 32px;
    }
}
.el-form {
    .flex();
    flex-wrap: wrap;
    width: 100%;
}
</style>
